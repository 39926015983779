import React, { useEffect } from 'react';
import styled from 'styled-components';
import { t } from 'src/utils/commonMethods';
import { accordionHelper } from 'src/veneerHelper';
import { HpAccordionTitle } from 'src/styles/global';
import { Accordion, Button, IconWarningAlt } from '@veneer/core';

const NoItemsAccordion = (props: {
  anchorId: string;
  title: string;
  msg: string;
  isError: boolean;
  retryAction: () => any;
}) => {
  const { accordionState, accordionSetState, accordionFunc } = accordionHelper();

  useEffect(() => {
    const items = createAccordionItems();
    accordionSetState.setItems(items);
  }, []);

  const createAccordionItems = () => {
    return [
      {
        content: (
          <NoItemsArea>
            <IconWrapper>
              <IconWarningAlt size={24} />
            </IconWrapper>

            <div>{props.msg}</div>

            {props.isError && (
              <RetryWrapper>
                <Button
                  data-testid="no-items-accordion-retry-btn"
                  appearance="ghost"
                  onClick={props.retryAction}
                >
                  {t('retry')}
                </Button>
              </RetryWrapper>
            )}
          </NoItemsArea>
        ),
        header: {
          centralArea: <HpAccordionTitle>{props.title}</HpAccordionTitle>,
          id: props.anchorId,
        },
        id: `${props.anchorId}-body`,
        expanded: true,
      },
    ];
  };

  return (
    <Accordion
      appearance="dropShadow"
      items={accordionState.items}
      onExpand={accordionFunc.handleExpand}
      onCollapse={accordionFunc.handleCollapse}
      data-testid="no-items-accordion"
    />
  );
};

// default values about props
NoItemsAccordion.defaultProps = {
  noItemsMessage: 'No Items Found',
};

const NoItemsArea = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 20px;
  background: rgba(33, 33, 33, 0.1);
  margin-bottom: 3px;
`;

const RetryWrapper = styled.div`
  margin-top: 10px;
`;

export default NoItemsAccordion;
