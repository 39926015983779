import React from 'react';
import styled from 'styled-components';
import { t } from 'src/utils/commonMethods';
import { useDispatch } from 'react-redux';
import { Button, IconWarningAlt } from '@veneer/core';
import { storeRefreshGetAppInfo } from 'src/store/overview/action';

type TAppListNoItemsProps = {
  error: boolean;
};
const AppListNoItems = (props: TAppListNoItemsProps) => {
  const dispatch = useDispatch();
  const getEmptyMessage = () => {
    if (props.error) {
      return t('section.unable_to_load_data');
    }
    return t('section.apps.empty');
  };

  return (
    <NoItemsArea>
      <IconWrapper>
        <IconWarningAlt size={24} />
      </IconWrapper>
      <div>{getEmptyMessage()}</div>

      {props.error && (
        <RetryWrapper>
          <Button
            appearance="ghost"
            onClick={() => {
              //props.setRefreshAppInfo(true);
              dispatch(storeRefreshGetAppInfo(true));
            }}
            data-testid="app-list-no-items-retry-button"
          >
            {t('retry')}
          </Button>
        </RetryWrapper>
      )}
    </NoItemsArea>
  );
};

const NoItemsArea = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 20px;
  background: rgba(33, 33, 33, 0.1);
  margin-bottom: 3px;
`;

const RetryWrapper = styled.div`
  margin-top: 10px;
`;

export default AppListNoItems;
