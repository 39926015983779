export const filterOptions = {
  outputLevel: 'minimal',
  sortBy: 'connectionState',
  order: 'descending',
  offset: 0,
  limit: 25,
  search: '',
};

export const dateFormat = {
  default: 'MMM DD, YYYY hh:mm:ss A', // March 17th, 2022 02:45:30 PM (12 hours format)
};

export const service = {
  deviceCache: 'deviceCache',
  fleetMgt: 'fleetMgt',
  collection: 'collection',
  appDeploy: 'appDeploy',
  contentBroker: 'contentBroker',
  fleetProxy: 'fleetProxy',
};

export const module = {
  tasks: 'tasks',
};

export const defaultAnchorPosition: any = {
  position: 'relative',
  start: 16,
};

export const store = {
  overview: {
    START_ROOT_COMPONENT: 'overview/startRootComponent',
    DEVICE_ID: 'overview/deviceId',
    IS_FROM_UNASSIGNED: 'overview/isFromUnassigned',
    GROUPS: 'overview/groups',
    DETAILS: 'overview/details',
    CONN_TYPES: 'overview/connTypes',
    ASSIGNMENTS: 'overview/assignments',
    APP_LIST: 'overview/appList',
    APP_CONTENTS: 'overview/appContents',
    ERROR_FOR_GET_DEVICE_INFO: 'overview/errorForGetDeviceInfo',
    ERROR_FOR_GET_FLEET_PROXY: 'overview/errorForGetFleetProxy',
    ERROR_FOR_GET_ASSIGNMENT: 'overview/errorForGetAssignment',
    ERROR_FOR_GET_APP_INFO: 'overview/errorForGetAppInfo',
    REFRESH_GET_FLEET_PROXY: 'overview/refreshGetFleetProxy',
    REFRESH_GET_ASSIGNMENT: 'overview/refreshGetAssignment',
    REFRESH_GET_APP_INFO: 'overview/refreshGetAppInfo',
  },
};

export const anchorIdObj = {
  generalInfo: 'item-general-information',
  usage: 'item-usage',
  warranty: 'item-warranty',
  policy: 'item-policy',
  solutionsAndServices: 'item-solutionsAndServices',
  connType: 'item-connectivity-type',
};

export const origin = {
  ECP_LOCAL: 'http://ecp.local',
  DEV: 'https://ecp.dev.portalshell.int.hp.com',
  PIE: 'https://ecp.pie.portalshell.int.hp.com',
  STAGE: 'https://ecp.stage.portalshell.int.hp.com',
  PROD: 'https://hp-commandcenter.com',
};

export const CURRENT_STACK = {
  DEV: window.location.origin === origin.DEV,
  LOCAL: window.location.origin.includes(origin.ECP_LOCAL),
  PIE: window.location.origin === origin.PIE,
  STAGE: window.location.origin === origin.STAGE,
  PROD: window.location.origin === origin.PROD,
};
