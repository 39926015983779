import { refined, refinedDate } from 'src/utils/commonMethods';

export const refinedFetchCompliance = (data) => {
  const refinedPolicies = (data) => {
    if (data === null || data === undefined) {
      const emptyPolicy = {
        policyId: '--',
        name: '--',
        status: '--',
        attributes: '--',
      };
      return [emptyPolicy];
    } else {
      return data.map((policy) => ({
        policyId: refined(policy.policyId),
        name: refined(policy.name),
        status: refined(policy.status),
        attributes: refined(policy.attributes),
      }));
    }
  };

  return {
    deviceId: refined(data.deviceId),
    serialNumber: refined(data.serialNumber),
    model: refined(data.model),
    status: refined(data.status),
    lastRunAt: refinedDate(data.lastRunAt, 'MMM DD, YYYY hh:mm A'),
    policies: refinedPolicies(data.policies),
  };
};
