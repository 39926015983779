import React from 'react';
import styled from 'styled-components';
import { t } from 'src/utils/commonMethods';

type TAppStatusProps = {
  appState: string;
  deployState: string;
};

const AppStatus = (props: TAppStatusProps) => {
  const getAppInstallationStatus = () => {
    let statusColor = '';
    let installText = '';

    const isInstalled = props.appState === 'EXIST';
    const isNotInstalled = props.appState !== 'EXIST' && props.deployState === 'FAILED';
    const isInstalling =
      props.appState !== 'EXIST' &&
      (props.deployState === 'RUNNING' || props.deployState === 'READY');

    if (isInstalled) {
      statusColor = 'green';
      installText = t('section.apps.installed');
    } else if (isInstalling) {
      statusColor = 'blue';
      installText = t('section.apps.installing');
    } else if (isNotInstalled) {
      statusColor = 'red';
      installText = t('section.apps.not_installed');
    }

    return (
      <Wrapper>
        <AppIcon data-testid="app-status-app-icon" color={statusColor} />
        <AppText data-testid="app-status-app-text">{installText}</AppText>
      </Wrapper>
    );
  };

  return <>{getAppInstallationStatus()}</>;
};

const Wrapper = styled.div`
  max-width: 224px;
  display: flex;
  align-items: center;
`;

const AppIcon = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${(props) => {
    switch (props.color) {
      case 'green':
        return '#1C7A17';
      case 'blue':
        return '#0278AB';
      case 'red':
      default:
        return '#BE1313';
    }
  }};
  margin-right: 8px;
  display: inline-block;
  border-radius: 50%;
`;

const AppText = styled.div`
  font-weight: bold;
`;

export default AppStatus;
