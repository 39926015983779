import 'src/styles/global.scss';
import React from 'react';
import { setProps } from 'src/utils/commonMethods';
import { useDispatch } from 'react-redux';
import { SectionsPage } from 'src/components/page';
import { useStoreState } from 'src/store/useStoreState';
import { useConstructor } from 'src/hooks/useConstructor';
import { getOverviewStoreActions } from 'src/store/overview/init';

export const App = (props) => {
  const { overviewState } = useStoreState();
  const { startRootComponent, deviceId } = overviewState;
  const dispatch = useDispatch();
  const dispatchAll = (list: any[]) => list.forEach((e) => dispatch(e));

  // init all redux stores
  useConstructor(() => {
    setProps();

    dispatchAll(getOverviewStoreActions(props));
  });

  const didReduxUpdated = () => {
    return startRootComponent && deviceId !== '';
  };

  const renderApp = () => {
    if (didReduxUpdated()) {
      return <SectionsPage />;
    } else {
      return <></>;
    }
  };

  return renderApp();
};
