import React, { useMemo } from 'react';
import styled from 'styled-components';
import { t } from 'src/utils/commonMethods';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { useStoreState } from 'src/store/useStoreState';
import { SideAnchor } from 'src/components/atom';
import { SubMfeSecureFleetManager } from 'src/components/molecule';
import {
  PolicySection,
  SolutionSection,
  GeneralInfoSection,
  ConnectivityTypeSection,
} from 'src/components/organism';

const SectionsTemplate = () => {
  const { overviewState } = useStoreState();
  const { errorForGetDeviceInfo } = overviewState;
  const { isWex, isFromApps } = useShellRootContext();

  const memoRenderSectionsTemplate = useMemo(() => {
    if (errorForGetDeviceInfo) {
      return (
        <ErrorWrapper>
          <ErrorMessage data-testid="device-errmsg">{t('error.unexpected_result')}</ErrorMessage>
        </ErrorWrapper>
      );
    } else {
      return (
        <ContentWrapper>
          <SectionsWrapper>
            <GeneralInfoSection data-testid="general-info-section" />
            {!isFromApps && <ConnectivityTypeSection />}
            {!(isWex || isFromApps) && <PolicySection />}
            {!isWex && <SolutionSection />}
            {!isWex && isFromApps && <SubMfeSecureFleetManager useMockData={false} />}
          </SectionsWrapper>
          <SideAnchor />
        </ContentWrapper>
      );
    }
  }, [errorForGetDeviceInfo]);

  return <>{memoRenderSectionsTemplate}</>;
};

const ErrorWrapper = styled.div`
  margin-bottom: 15px;
  background-color: #ffffff;
  color: #212121;
  border-color: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  box-sizing: inherit;
  height: 100px;
`;

const ErrorMessage = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  flex-direction: row;
  display: flex;
`;

const SectionsWrapper = styled.div`
  width: 80%;
  transition: all 1s ease;

  @media (max-width: 768px) {
    width: 100%;
    transition: all 1s ease;
  }
`;
export default SectionsTemplate;
