import { extendSemantics } from '@veneer/semantics';
import primitives from '@veneer/primitives';

const color = primitives.color;
const defaultForeground = '#16141d';
const hoverColor1 = '#fe6aff';
const activeColor1 = '#00d3ff';
const defaultBase1 = '#ff8900';
const defaultBase2 = '#ff6445';

const getWexColors = (colors) => {
  return {
    foreground: {
      default: colors[3],
      hover: colors[3],
      active: colors[3],
    },
    base: {
      default: colors[0],
      hover: colors[1],
      active: colors[2],
    },
    background: {
      default: colors[0] + '1A',
      hover: colors[0] + '0D',
      active: colors[0] + '1A',
    },
  };
};

const White = '#FFFFFF';

const getContrastWexColors = (colors) => {
  return {
    foreground: {
      default: colors[3],
      hover: colors[3],
      active: colors[3],
    },
    base: {
      default: colors[0],
      hover: colors[1],
      active: colors[2],
    },
    background: {
      default: colors[3],
      hover: colors[3],
      active: colors[3],
    },
  };
};

const ColorOverride = {
  wex: {
    light: {
      primary: {
        ...getWexColors([color.darkNavy7, color.darkNavy8, color.darkNavy9, White]),
      },
      neutral: {
        ...getWexColors([color.gray7, color.darkNavy8, color.darkNavy9, White]),
      },
      positive: {
        ...getWexColors([color.green7, color.green8, color.green9, White]),
      },
      negative: {
        ...getWexColors([color.red7, color.red8, color.red9, White]),
      },
      warning: {
        ...getWexColors([color.darkOrange5, color.darkOrange6, color.darkOrange7, White]),
      },
      foreground: {
        default: color.gray12,
        medium: color.gray9,
        light: color.gray8,
        inverse: White,
        disabled: color.gray6,
      },
      background: {
        default: color.gray0,
        container: White,
        disabled: color.gray2,
        shell: White,
      },
      stroke: {
        default: color.gray3,
      },
    },

    dark: {
      primary: {
        ...getWexColors([color.darkNavy5, color.darkNavy4, color.darkNavy3, color.gray12]),
      },
      neutral: {
        ...getWexColors([color.gray5, color.gray4, color.gray3, color.gray12]),
      },
      positive: {
        ...getWexColors([color.green5, color.green4, color.green3, color.gray12]),
      },
      negative: {
        ...getWexColors([color.red5, color.red4, color.red3, color.gray12]),
      },
      warning: {
        ...getWexColors([color.darkOrange5, color.darkOrange4, color.darkOrange3, color.gray12]),
      },
      foreground: {
        default: White,
        medium: color.gray3,
        light: color.gray5,
        inverse: color.gray12,
        disabled: color.gray7,
      },
      background: {
        default: color.gray12,
        container: color.gray11,
        disabled: color.gray9,
        shell: color.gray12,
      },
      stroke: {
        default: color.gray8,
      },
    },

    contrast: {
      primary: {
        ...getContrastWexColors([
          color.highContrastYellow,
          color.highContrastPink,
          color.highContrastBlue,
          color.highContrastEigengrau,
        ]),
      },
      neutral: {
        ...getContrastWexColors([
          color.highContrastYellow,
          color.highContrastPink,
          color.highContrastBlue,
          color.highContrastEigengrau,
        ]),
      },
      positive: {
        ...getContrastWexColors([
          color.green5,
          color.highContrastPink,
          color.highContrastBlue,
          color.highContrastEigengrau,
        ]),
      },
      negative: {
        ...getContrastWexColors([
          color.red5,
          color.highContrastPink,
          color.highContrastBlue,
          color.highContrastEigengrau,
        ]),
      },
      warning: {
        ...getContrastWexColors([
          color.darkOrange5,
          color.highContrastPink,
          color.highContrastBlue,
          color.highContrastEigengrau,
        ]),
      },
      foreground: {
        default: color.white,
        medium: color.white,
        light: color.white,
        inverse: color.highContrastEigengrau,
        disabled: color.highContrastGray,
      },
      background: {
        default: color.highContrastEigengrau,
        container: color.highContrastEigengrau,
        disabled: color.gray8,
        shell: color.highContrastEigengrau,
      },
      stroke: {
        default: color.highContrastGray,
      },
    },
  },

  default: {
    light: {
      primary: {
        background: { default: 'rgba(2, 120, 171, 0.05)' },
        base: { active: '#014667' },
      },
    },
    dark: {},
    contrast: {},
  },
};

const DefaultColor = {
  light: {
    foreground: {
      default: color.gray12, //'#212121',
      medium: color.gray9, //'#404040',
      light: color.gray7, //'#666666',
      disabled: color.gray5, //'#a3a3a3',
    },
    background: {
      default: color.gray0, //'#fafafa',
      container: color.white,
      disabled: color.gray2, //'#e8e8e8',
    },
    stroke: {
      default: color.gray3, //'#d9d9d9',
    },
    effects: {
      glasswall: 'rgba(33,33,33,0.5)',
      dropShadow: 'rgba(33,33,33,0.2)',
    },
    spotlight: {
      foreground: {
        default: color.gray12, //'#212121',
        hover: color.gray12, //'#212121',
        active: color.gray12, //'#212121',
      },
      base: {
        default: color.white,
        hover: 'rgba(255,255,255,0.8)',
        active: 'rgba(255,255,255,0.6)',
      },
      background: {
        default: color.gray12, //'#212121',
        hover: 'rgba(255,255,255,0.1)',
        active: 'rgba(255,255,255,0.2)',
      },
    },
    primary: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: '#0073a8',
        hover: '#015985',
        active: '#02476b',
      },
      background: {
        default: '#e6f0f5',
        hover: '#ceeffe',
        active: '#b7e8fe',
      },
    },
    neutral: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: color.gray7, //'#666666',
        hover: color.gray8, //'#545454',
        active: color.gray9, //'#404040',
      },
      background: {
        default: '#f1f1f1',
        hover: 'rgba(33,33,33,0.1)',
        active: 'rgba(33,33,33,0.2)',
      },
    },
    positive: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: '#00834b',
        hover: '#006c3e',
        active: '#005a35',
      },
      background: {
        default: '#d5f5e7',
        hover: '#ccffe8',
        active: '#a3ffd6',
      },
    },
    warning: {
      foreground: {
        default: color.gray12, //'#212121',
        hover: color.gray12, //'#212121',
        active: color.gray12, //'#212121',
      },
      base: {
        default: defaultBase1,
        hover: '#e07200',
        active: '#b2600c',
      },
      background: {
        default: '#fcebdc',
        hover: '#ffdbb2',
        active: '#ffc98c',
      },
    },
    negative: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: '#cc2c00',
        hover: '#9f2301',
        active: '#802208',
      },
      background: {
        default: '#faeceb',
        hover: '#ffdbd9',
        active: '#ffc1bd',
      },
    },
    caution: {
      foreground: {
        default: '#5c4e00',
        hover: color.gray12, //'#212121',
        active: color.gray12, //'#212121',
      },
      base: {
        default: '#ffe137',
        hover: '#f9d300',
        active: '#e5c300',
      },
      background: {
        default: '#f7f2cb',
        hover: '#fff29e',
        active: '#ffe970',
      },
    },
    help: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: '#187585',
        hover: '#176673',
        active: '#16555f',
      },
      background: {
        default: '#d7f4f7',
        hover: '#d4fbff',
        active: '#bcf7ff',
      },
    },
  },
  dark: {
    foreground: {
      default: color.gray12, //'#212121',
      medium: color.gray9, //'#404040',
      light: color.gray7, //'#666666',
      disabled: color.gray5, //'#a3a3a3',
    },
    background: {
      default: color.gray0, //'#fafafa',
      container: color.white,
      disabled: color.gray2, //'#e8e8e8',
    },
    stroke: {
      default: color.gray3, //'#d9d9d9',
    },
    effects: {
      glasswall: 'rgba(33,33,33,0.5)',
      dropShadow: 'rgba(33,33,33,0.2)',
    },
    spotlight: {
      foreground: {
        default: color.gray12, //'#212121',
        hover: color.gray12, //'#212121',
        active: color.gray12, //'#212121',
      },
      base: {
        default: color.white,
        hover: 'rgba(255,255,255,0.8)',
        active: 'rgba(255,255,255,0.6)',
      },
      background: {
        default: color.gray12, //'#212121',
        hover: 'rgba(255,255,255,0.1)',
        active: 'rgba(255,255,255,0.2)',
      },
    },
    primary: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: '#0073a8',
        hover: '#015985',
        active: '#02476b',
      },
      background: {
        default: '#e6f0f5',
        hover: '#ceeffe',
        active: '#b7e8fe',
      },
    },
    neutral: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: color.gray7, //'#666666',
        hover: color.gray8, //'#545454',
        active: color.gray9, //'#404040',
      },
      background: {
        default: '#f1f1f1',
        hover: 'rgba(33,33,33,0.1)',
        active: 'rgba(33,33,33,0.2)',
      },
    },
    positive: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: '#00834b',
        hover: '#006c3e',
        active: '#005a35',
      },
      background: {
        default: '#d5f5e7',
        hover: '#ccffe8',
        active: '#a3ffd6',
      },
    },
    warning: {
      foreground: {
        default: color.gray12, //'#212121',
        hover: color.gray12, //'#212121',
        active: color.gray12, //'#212121',
      },
      base: {
        default: defaultBase1,
        hover: '#e07200',
        active: '#b2600c',
      },
      background: {
        default: '#fcebdc',
        hover: '#ffdbb2',
        active: '#ffc98c',
      },
    },
    negative: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: '#cc2c00',
        hover: '#9f2301',
        active: '#802208',
      },
      background: {
        default: '#faeceb',
        hover: '#ffdbd9',
        active: '#ffc1bd',
      },
    },
    caution: {
      foreground: {
        default: '#5c4e00',
        hover: color.gray12, //'#212121',
        active: color.gray12, //'#212121',
      },
      base: {
        default: '#ffe137',
        hover: '#f9d300',
        active: '#e5c300',
      },
      background: {
        default: '#f7f2cb',
        hover: '#fff29e',
        active: '#ffe970',
      },
    },
    help: {
      foreground: {
        default: color.white,
        hover: color.white,
        active: color.white,
      },
      base: {
        default: '#187585',
        hover: '#176673',
        active: '#16555f',
      },
      background: {
        default: '#d7f4f7',
        hover: '#d4fbff',
        active: '#bcf7ff',
      },
    },
  },
  contrast: {
    foreground: {
      default: color.white,
      medium: color.white,
      light: color.white,
      disabled: '#adadad',
    },
    background: {
      default: defaultForeground,
      container: defaultForeground,
      disabled: color.gray8, //'#545454',
    },
    stroke: {
      default: '#adadad',
    },
    effects: {
      glasswall: defaultForeground,
      dropShadow: 'rgba(22,20,29,0)',
    },
    spotlight: {
      foreground: {
        default: defaultForeground,
        hover: defaultForeground,
        active: defaultForeground,
      },
      base: {
        default: '#fff700',
        hover: hoverColor1,
        active: activeColor1,
      },
      background: {
        default: color.white,
        hover: hoverColor1,
        active: activeColor1,
      },
    },
    primary: {
      foreground: {
        default: defaultForeground,
        hover: defaultForeground,
        active: defaultForeground,
      },
      base: {
        default: '#fff700',
        hover: hoverColor1,
        active: activeColor1,
      },
      background: {
        default: defaultForeground,
        hover: hoverColor1,
        active: activeColor1,
      },
    },
    neutral: {
      foreground: {
        default: defaultForeground,
        hover: defaultForeground,
        active: defaultForeground,
      },
      base: {
        default: '#fff700',
        hover: hoverColor1,
        active: activeColor1,
      },
      background: {
        default: defaultForeground,
        hover: hoverColor1,
        active: activeColor1,
      },
    },
    positive: {
      foreground: {
        default: defaultForeground,
        hover: defaultForeground,
        active: defaultForeground,
      },
      base: {
        default: '#00d072',
        hover: hoverColor1,
        active: activeColor1,
      },
      background: {
        default: defaultForeground,
        hover: hoverColor1,
        active: activeColor1,
      },
    },
    warning: {
      foreground: {
        default: defaultForeground,
        hover: defaultForeground,
        active: defaultForeground,
      },
      base: {
        default: defaultBase1,
        hover: hoverColor1,
        active: activeColor1,
      },
      background: {
        default: defaultForeground,
        hover: hoverColor1,
        active: activeColor1,
      },
    },
    negative: {
      foreground: {
        default: defaultForeground,
        hover: defaultForeground,
        active: defaultForeground,
      },
      base: {
        default: defaultBase2,
        hover: hoverColor1,
        active: activeColor1,
      },
      background: {
        default: defaultForeground,
        hover: hoverColor1,
        active: activeColor1,
      },
    },
    caution: {
      foreground: {
        default: defaultForeground,
        hover: defaultForeground,
        active: defaultForeground,
      },
      base: {
        default: '#ffe137',
        hover: hoverColor1,
        active: activeColor1,
      },
      background: {
        default: defaultForeground,
        hover: hoverColor1,
        active: activeColor1,
      },
    },
    help: {
      foreground: {
        default: defaultForeground,
        hover: defaultForeground,
        active: defaultForeground,
      },
      base: {
        default: '#2ac5e0',
        hover: hoverColor1,
        active: activeColor1,
      },
      background: {
        default: defaultForeground,
        hover: hoverColor1,
        active: activeColor1,
      },
    },
  },
};

const PaletteCache = {};
export const getPalette = (mode = 'light', selectedColor = 'wex') => {
  if (PaletteCache[selectedColor] && PaletteCache[selectedColor][mode]) {
    return JSON.parse(JSON.stringify(PaletteCache[selectedColor][mode]));
  } else {
    const _palette = JSON.parse(JSON.stringify(DefaultColor[mode]));
    const _override =
      ColorOverride[selectedColor] && ColorOverride[selectedColor][mode]
        ? ColorOverride[selectedColor][mode]
        : ColorOverride.default[mode];
    if (_override) {
      for (const key in _override) {
        _palette[key] = {
          ..._palette[key],
          ..._override[key],
        };
      }
    }
    PaletteCache[selectedColor] = PaletteCache[selectedColor] || {};
    PaletteCache[selectedColor][mode] = _palette;
    return JSON.parse(JSON.stringify(_palette));
  }
};

export const getSystem = (selectedColor = 'wex') => {
  return extendSemantics({
    color: {
      light: getPalette('light', selectedColor),
      dark: getPalette('dark', selectedColor),
      // contrast: getPalette('contrast', selectedColor)
    },
  });
};
