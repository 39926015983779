import React, { useMemo } from 'react';
import styled from 'styled-components';
import { t } from 'src/utils/commonMethods';
import { useDispatch } from 'react-redux';
import { anchorIdObj } from 'src/utils/constants';
import { useStoreState } from 'src/store/useStoreState';
import { accordionHelper } from 'src/veneerHelper';
import { SectionListItem } from 'src/components/molecule';
import { HpAccordionTitle } from 'src/styles/global';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { storeRefreshGetAssignment } from 'src/store/overview/action';
import { CommonLoading, NoItemsAccordion } from 'src/components/atom';
import { Accordion, IconCheckmarkCircle, IconXCircle, List } from '@veneer/core';

const PolicySection = () => {
  const dispatch = useDispatch();
  const { deviceId } = useShellRootContext();
  const { overviewState } = useStoreState();
  const { assignments, errorForGetAssignment, refreshGetAssignment } = overviewState;
  const { accordionState, accordionFunc } = accordionHelper(createAccordionItems(assignments));

  function getPolicyDescription(assignments) {
    return t('section.policies.last_run_on') + ' ' + assignments.lastRunAt;
  }

  function getPolicyName(_name) {
    return _name === '--' ? t('section.policies.no_policy_assigned') : _name;
  }

  function getPolicyStatus(status) {
    if (status === 'compliant') {
      return (
        <StatusArea>
          <IconCheckmarkCircle color="chartreuse6" filled={true} />
          <Text>{t('Compliant')}</Text>
        </StatusArea>
      );
    } else {
      return (
        <StatusArea>
          <IconXCircle color="red7" filled={true} />
          <Text>{t('Noncompliant')}</Text>
        </StatusArea>
      );
    }
  }

  function getDetailsUrl(_policyName, _policyId) {
    if (_policyName === '--') {
      return `/devices/${deviceId}/policy`;
    }
    return `/policies/${_policyId}`;
  }

  function createAccordionItems(assignments) {
    if (assignments.length === 0) {
      return [];
    }
    return [
      {
        content: (
          <List className={'sections__sub-accordion-item'}>
            {assignments.policies.map((policy, idx) => (
              <SectionListItem
                key={`policy-section-list-item-${idx}`}
                title={getPolicyName(policy.name)}
                description={getPolicyDescription(assignments)}
                status={<PolicyStatus>{getPolicyStatus(policy.status)}</PolicyStatus>}
                url={getDetailsUrl(policy.name, policy.policyId)}
              />
            ))}
          </List>
        ),
        header: {
          centralArea: <HpAccordionTitle>{t('label.policies')}</HpAccordionTitle>,
          id: anchorIdObj.policy,
        },
        id: `${anchorIdObj.policy}-body`,
        expanded: true,
      },
    ];
  }

  const getEmptyMessage = () => {
    if (errorForGetAssignment) {
      return t('section.policies.error');
    }
    return t('section.policies.empty');
  };

  const memoRenderPolicySection = useMemo(() => {
    if (refreshGetAssignment) return <CommonLoading data-testid="policy-section-loading" />;
    else if (
      errorForGetAssignment ||
      assignments.policies == undefined ||
      assignments.policies.length === 0
    ) {
      return (
        <Wrapper>
          <NoItemsAccordion
            anchorId={anchorIdObj.policy}
            title={t('section.policies.title')}
            msg={getEmptyMessage()}
            // isError={errorForGetAssignment}
            isError={true}
            retryAction={() => {
              dispatch(storeRefreshGetAssignment(true));
            }}
            data-testid="policy-section-no-item-accordion"
          />
        </Wrapper>
      );
    } else
      return (
        <Wrapper>
          <Accordion
            border="dropShadow"
            items={accordionState.items}
            onExpand={accordionFunc.handleExpand}
            onCollapse={accordionFunc.handleCollapse}
          />
        </Wrapper>
      );
  }, [refreshGetAssignment, assignments, errorForGetAssignment]);

  return <>{memoRenderPolicySection}</>;
};

const Wrapper = styled.div`
  margin-bottom: 15px;
  background-color: #ffffff;
  color: #212121;
  border-color: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  box-sizing: inherit;
`;

const StatusArea = styled.div`
  min-width: 120px;
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  margin-left: 8px;

  font-family: Forma DJR Micro, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
`;

const PolicyStatus = styled.div`
  font-weight: bold;
  text-align: left;
`;

export default PolicySection;
