import useApiClient from 'src/api/useApiClient';
import { service } from 'src/utils/constants';
import { isMockUpMode } from 'src/utils/commonMethods';
import { refinedFetchCollectionByEntityId, refinedFetchCollections } from './collectionRefinery';

import getCollectionByEntityIdMock from 'src/mock/collection/get-collection-by-entity-id.json';
import getCollectionsMock from 'src/mock/collection/get-collections.json';

export const collectionAPI = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.collection);

  const getCollectionByEntityId = async (deviceId, options) => {
    let response, error;

    if (isMockUpMode()) {
      response = refinedFetchCollectionByEntityId(getCollectionByEntityIdMock).contents;

      // await getMockUpData('collection/get-collection-by-entity-id').then((data) => {
      //   response = refinedFetchCollectionByEntityId(data).contents;
      // });
      return { response, error };
    }

    await client
      .getCollectionByEntityId('devices', deviceId, options.offset, options.limit, options.sortBy)
      .then((res) => {
        response = refinedFetchCollectionByEntityId(res.data).contents;
      })
      .catch((err) => {
        error = err;
      });
    return { response, error };
  };

  const getCollections = async (offset, limit, name = undefined) => {
    let response, error;

    if (isMockUpMode()) {
      response = refinedFetchCollections(getCollectionsMock);

      // await getMockUpData('collection/get-collections').then((data) => {
      //   response = refinedFetchCollections(data);
      // });
      return { response, error };
    }

    await client
      .getCollections(offset, limit, undefined, name)
      .then((res) => {
        response = refinedFetchCollections(res.data);
      })
      .catch((err) => {
        error = err;
      });
    return { response, error };
  };

  return {
    getCollectionByEntityId,
    getCollections,
  };
};
