import { refined } from 'src/utils/commonMethods';

export const refinedFetchCollectionByEntityId = (data) => {
  return {
    contents:
      data.contents?.length > 0
        ? data.contents.map((obj) => ({
            id: refined(obj.id),
            name: refined(obj.name),
          }))
        : [{ id: refined(undefined), name: refined(undefined) }],
  };
};

export const refinedFetchCollections = (data) => {
  return {
    contents:
      data.contents?.length > 0
        ? data.contents.map((obj) => ({
            id: refined(obj.id),
          }))
        : [{ id: refined(undefined) }],
  };
};
