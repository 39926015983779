import useApiClient from 'src/api/useApiClient';
import { service } from 'src/utils/constants';
import { isMockUpMode } from 'src/utils/commonMethods';
import { refinedFetchDeviceInfo } from './deviceCacheRefinery';

import getDeviceInfoMock from 'src/mock/deviceCache/get-device-info.json';

export const deviceCacheAPI = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.deviceCache);

  const getDeviceInfo = async (deviceId) => {
    let response, error;

    if (isMockUpMode()) {
      response = refinedFetchDeviceInfo(getDeviceInfoMock);

      // await getMockUpData('deviceCache/get-device-info').then((data) => {
      //   response = refinedFetchDeviceInfo(data);
      // });
      return { response, error };
    }

    await client
      .show(deviceId)
      .then((res) => {
        response = refinedFetchDeviceInfo(res.data);
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };
  return {
    getDeviceInfo,
  };
};
