import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const HpAccordionTitle = styled.label`
  height: 28px;
  padding: 6px 12px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;

  display: flex;
  align-items: center;
  color: #212121;
`;

export const HpSectionWrapper = styled.div`
  margin-bottom: 15px;
  background-color: #ffffff;
  color: #212121;
  border-color: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  box-sizing: inherit;

  .sections__sub-accordion-item {
    padding: 12px;
  }
`;

export const HpLinkIcon = styled.div`
  color: ${(props) => (props.isWex ? tokens.color.darkNavy7 : '#027aae')};
  cursor: pointer;
`;
