import React, { useMemo } from 'react';
import styled from 'styled-components';
import { t } from 'src/utils/commonMethods';
import { useDispatch } from 'react-redux';
import { anchorIdObj } from 'src/utils/constants';
import { useStoreState } from 'src/store/useStoreState';
import { accordionHelper } from 'src/veneerHelper';
import { SectionListItem } from 'src/components/molecule';
import { HpAccordionTitle } from 'src/styles/global';
import { storeRefreshGetFleetProxy } from 'src/store/overview/action';
import { Accordion, IconCircle, List } from '@veneer/core';
import { CommonLoading, NoItemsAccordion } from 'src/components/atom';

const ConnectivityTypeSection = () => {
  const dispatch = useDispatch();
  const { overviewState } = useStoreState();
  const { refreshGetFleetProxy, connTypes, errorForGetFleetProxy } = overviewState;
  const { accordionState, accordionFunc } = accordionHelper(createAccordionItems());

  function getFleetProxyStatus(status) {
    if (status === 'online') {
      return (
        <StatusArea>
          <IconCircle color="chartreuse6" filled={true} size={12} />
          <Text>{t('section.connType.online')}</Text>
        </StatusArea>
      );
    } else {
      return (
        <StatusArea>
          <IconCircle color="red7" filled={true} size={12} />
          <Text>{t('section.connType.offline')}</Text>
        </StatusArea>
      );
    }
  }

  function getConnTypeText(connType) {
    switch (connType) {
      case 'print-iot':
        return 'Cloud';
      case 'fleet-proxy':
        return 'Proxy';
      case 'usb':
      default:
        return 'Local';
    }
  }

  function getDescription(connType) {
    return `${getConnTypeText(connType.connType)} | ${t('section.connType.last_updated')}: ${
      connType.lastUpdated
    }`;
  }

  function createAccordionItems() {
    return [
      {
        content: (
          <List className={'sections__sub-accordion-item'}>
            {connTypes.map((connType) => (
              <SectionListItem
                key={`connectivity-type-section-list-item-${connType.name}`}
                title={connType.name}
                description={getDescription(connType)}
                status={
                  <ConnectivityStatus>{getFleetProxyStatus(connType.status)}</ConnectivityStatus>
                }
                url={connType.url}
              />
            ))}
          </List>
        ),
        header: {
          centralArea: <HpAccordionTitle>{t('label.connectivity_type')}</HpAccordionTitle>,
          id: anchorIdObj.connType,
        },
        id: `${anchorIdObj.connType}-body`,
        expanded: true,
      },
    ];
  }
  const memoRenderConnectivityTypeSection = useMemo(() => {
    if (refreshGetFleetProxy)
      return <CommonLoading data-testid="connectivity-type-section-loading" />;
    else if (connTypes.length == 0)
      return (
        <Wrapper>
          <NoItemsAccordion
            anchorId={anchorIdObj.connType}
            title={t('label.connectivity_type')}
            msg={t('section.connType.error')}
            isError={errorForGetFleetProxy}
            retryAction={() => {
              dispatch(storeRefreshGetFleetProxy(true));
            }}
            data-testid="connectivity-type-section-no-item-accordion"
          />
        </Wrapper>
      );
    else
      return (
        <Wrapper>
          <Accordion
            border="dropShadow"
            items={accordionState.items}
            onExpand={accordionFunc.handleExpand}
            onCollapse={accordionFunc.handleCollapse}
          />
        </Wrapper>
      );
  }, [refreshGetFleetProxy, connTypes, errorForGetFleetProxy, accordionState.items]);

  return <>{memoRenderConnectivityTypeSection}</>;
};

const Wrapper = styled.div`
  margin-bottom: 15px;
  background-color: #ffffff;
  color: #212121;
  border-color: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  box-sizing: inherit;
`;

const StatusArea = styled.div`
  min-width: 120px;
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  margin-left: 8px;
`;

const ConnectivityStatus = styled.div`
  font-family: Forma DJR Micro, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
`;

export default ConnectivityTypeSection;
