import useApiClient from 'src/api/useApiClient';
import { service } from 'src/utils/constants';
import { isMockUpMode } from 'src/utils/commonMethods';
import { refinedFetchCompliance } from './fleetMgtRefinery';

import getDeviceComplianceMock from 'src/mock/fleetMgt/get-device-compliance.json';

/**
 * Policy Compliance API
 */
export const fleetMgtAPI = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.fleetMgt);

  const getCompliance = async (deviceId) => {
    let error, response: any;

    if (isMockUpMode()) {
      response = refinedFetchCompliance(getDeviceComplianceMock);

      // await getMockUpData('fleetMgt/get-device-compliance').then((data) => {
      //   response = refinedFetchCompliance(data);
      // });
      return { response, error };
    }

    await client
      .getDevice(deviceId)
      .then((res) => {
        response = refinedFetchCompliance(res.data);
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    getCompliance,
  };
};
