import React from 'react';
import styled from 'styled-components';
import { t } from 'src/utils/commonMethods';
import { AppList } from 'src/components/molecule';
import { SolutionList } from 'src/components/atom';
import { accordionHelper } from 'src/veneerHelper';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { Accordion, IconShieldCheckmark } from '@veneer/core';

const SolutionSubSection = () => {
  const { featureFlags, isFromApps } = useShellRootContext();
  const solutionSubItemList = [
    {
      isFirstItem: true,
      icon: <IconShieldCheckmark size={30} />,
      title: 'HP Secure Fleet Manager',
      detail: t('section.solutionSvc.detail'),
      linkText: t('section.solutionSvc.link_text'),
    },
  ];
  const { accordionState, accordionFunc } = accordionHelper(createAccordionItem());

  function createSolutionAccordionItem() {
    return {
      content: <SolutionList subItemList={solutionSubItemList} />,
      header: {
        centralArea: <AccordionTitle>{t('label.solutions')}</AccordionTitle>,
        id: 'items-sub-solutions',
      },
      id: 'items-sub-solutions',
      expanded: true,
    };
  }

  function createAppListAccordionItem() {
    return {
      content: <AppList />,
      header: {
        centralArea: <AccordionTitle>{t('label.apps')}</AccordionTitle>,
        id: 'items-sub-apps',
      },
      id: 'items-sub-apps',
      expanded: true,
    };
  }

  function createAccordionItem() {
    const items = [];

    if (!isFromApps) {
      items.push(createSolutionAccordionItem());
    }
    if (featureFlags.enableAppList) {
      items.push(createAppListAccordionItem());
    }

    return items;
  }

  return (
    <div className={'sections__sub-accordion-item'} data-testid="solution-svc-section">
      <Accordion
        border="outlined"
        items={accordionState.items}
        onExpand={accordionFunc.handleExpand}
        onCollapse={accordionFunc.handleCollapse}
      />
    </div>
  );
};
const AccordionTitle = styled.div`
  font-size: 16px;
`;

export default SolutionSubSection;
