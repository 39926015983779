import React from 'react';
import styled from 'styled-components';
import { t } from 'src/utils/commonMethods';
import { ContentItem } from 'src/components/atom';

type TNetworkAdapterInfoProps = {
  adapters: any[];
  adapterType: string;
};
const NetworkAdapterInfo = (props: TNetworkAdapterInfoProps) => {
  const { adapters, adapterType } = props;

  return (
    <>
      {adapters
        .map((adapter, index) => {
          return (
            <>
              <NetworkTitle>
                {t(`section.general.${adapterType}`)} {index > 0 ? index + 1 : ''}
              </NetworkTitle>
              {adapter.ipv4.address.ip != 'unable to load data' && (
                <ContentItem
                  id={`${adapterType}${index}-ipv4-value`}
                  title={t('section.general.ipv4')}
                  value={adapter.ipv4.address.ip}
                />
              )}
              {adapter.ipv6.address.ip != 'unable to load data' && (
                <ContentItem
                  id={`${adapterType}${index}-ipv6-value`}
                  title={t('section.general.ipv6')}
                  value={adapter.ipv6.address.ip}
                />
              )}
              {adapter.macAddress != 'unable to load data' && (
                <ContentItem
                  id={`${adapterType}${index}-mac-value`}
                  title={t('section.general.mac')}
                  value={adapter.macAddress.replaceAll(':', '')}
                />
              )}
              {adapter.hostname != 'unable to load data' && (
                <ContentItem
                  id={`${adapterType}${index}-hostname-value`}
                  title={t('section.general.hostName')}
                  value={adapter.hostname}
                />
              )}
            </>
          );
        })
        // Filter out empty items
        .filter((item) => {
          return item.props.children.filter((child) => child == false).length < 4;
        })}
    </>
  );
};

const NetworkTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-top: 16px;
  padding-top: 24px;
  color: #212121;
`;

export default NetworkAdapterInfo;
