import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { MfeLoader } from 'src/components/atom';
import { useStoreState } from 'src/store/useStoreState';
import { useShellRootContext } from 'src/contexts/ShellRoot';

const SubMfeSecureFleetManager = (props: any) => {
  const { overviewState } = useStoreState();
  const { deviceId, isFromUnassigned } = overviewState;
  const shellRootProps = useShellRootContext();

  const SubMfe = useCallback(
    ({ component, type }) => {
      return (
        <div>
          <MfeLoader
            disableContainerPadding
            {...props}
            {...shellRootProps}
            {...{
              component,
              type,
              deviceId,
              isFromUnassigned,
              useMockData: props.useMockData,
            }}
          />
        </div>
      );
    },
    [shellRootProps.shell, shellRootProps.stack, props],
  );

  const memoRenderSubMfe = useMemo(() => {
    return (
      <div>
        <SubMfe
          type="ECPDevicesDetailsFleetManager"
          component="@jarvis/react-ecp-endpointsecurity-devicespage"
        />
      </div>
    );
  }, []);

  return <>{memoRenderSubMfe}</>;
};

SubMfeSecureFleetManager.defaultProps = {
  shell: {},
  stack: null,
  useMockData: false,
};

SubMfeSecureFleetManager.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any),
  stack: PropTypes.number,
};

export default SubMfeSecureFleetManager;
