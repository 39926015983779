import useApiClient from 'src/api/useApiClient';
import { service } from 'src/utils/constants';
import { isMockUpMode } from 'src/utils/commonMethods';
import { refinedConnectorProxy } from 'src/api/fleetProxy/fleetProxyRefinery';

import getFleetProxyConnectorProxyMock from 'src/mock/fleetProxy/get-fleet-proxy-connector-proxy.json';

/**
 * Fleet Proxy API
 */
export const fleetProxyAPI = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.fleetProxy);

  const getProxy = async (fleetProxyId) => {
    let response, error;

    if (isMockUpMode()) {
      response = refinedConnectorProxy(getFleetProxyConnectorProxyMock);

      // await getMockUpData('fleetProxy/get-fleet-proxy-connector-proxy').then((data) => {
      //   response = refinedConnectorProxy(data);
      // });
      return { response, error };
    }

    await client
      .getProxies(fleetProxyId)
      .then((res) => {
        response = refinedConnectorProxy(res.data);
      })
      .catch((err) => {
        error = err;
      });
    return { response, error };
  };

  return {
    getProxy,
  };
};
