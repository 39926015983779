import { isMockUpMode } from 'src/utils/commonMethods';
import {
  storeDeviceId,
  storeIsFromUnassigned,
  storeStartRootComponent,
} from 'src/store/overview/action';

export const getOverviewStoreActions = (props) => {
  if (isMockUpMode()) {
    return [
      storeStartRootComponent(true),
      storeDeviceId('en/us/devices/demoId'),
      storeIsFromUnassigned(props.isFromUnassigned ?? false),
    ];
  }
  return [
    storeStartRootComponent(true),
    storeDeviceId(props.deviceId),
    storeIsFromUnassigned(props.isFromUnassigned ?? false),
  ];
};
