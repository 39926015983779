import React from 'react';
import { ListItem } from '@veneer/core';
import { ListItemLeadingArea, ListItemTrailingArea } from 'src/components/atom';

type TSectionListItemProps = {
  title: string;
  description: string;
  status: any;
  url: string;
};
const SectionListItem = (props: TSectionListItemProps) => {
  return (
    <ListItem
      key={props.title}
      data-testid={`section-list-item-${props.title}`}
      leadingArea={<ListItemLeadingArea title={props.title} description={props.description} />}
      trailingArea={<ListItemTrailingArea status={props.status} url={props.url} />}
    />
  );
};

export default SectionListItem;
