import React from 'react';
import styled from 'styled-components';

type TListItemLeadingAreaProps = {
  title: string;
  description: string;
};

const ListItemLeadingArea = (props: TListItemLeadingAreaProps) => {
  return (
    <div>
      <Title data-testid="list-item-leading-area-title">{props.title}</Title>
      <Description>{props.description}</Description>
    </div>
  );
};

const Title = styled.div`
  font-family: Forma DJR UI, Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

const Description = styled.div`
  font-family: Forma DJR UI, Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

export default ListItemLeadingArea;
