import { refined, refinedDate } from 'src/utils/commonMethods';

export const refinedConnectorProxy = (data) => {
  return {
    id: refined(data.id),
    tenantId: refined(data.tenantId),
    proxyName: refined(data.proxyName),
    proxyType: refined(data.proxyType),
    softwareVersion: refined(data.softwareVersion),
    hostName: refined(data.hostName),
    devices: refined(data.devices),
    description: refined(data.description),
    proxyState: data.proxyState,
    connectionState: data.connectionState,
    dateAdded: refinedDate(data.dateAdded),
    lastUpdated: refinedDate(data.lastUpdated),
    version: refined(data.version),
  };
};
