import { store } from 'src/utils/constants';

const initialState = {
  startRootComponent: false,
  deviceId: '',
  isFromUnassigned: false,
  groups: [],
  details: null,
  connTypes: [],
  assignments: {},
  appList: [],
  appContents: [],
  errorForGetDeviceInfo: false,
  errorForGetFleetProxy: false,
  errorForGetAssignment: false,
  errorForGetAppInfo: false,
  refreshGetFleetProxy: false,
  refreshGetAssignment: false,
  refreshGetAppInfo: false,
};

const overviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.overview.START_ROOT_COMPONENT:
      return {
        ...state,
        startRootComponent: action.payload,
      };
    case store.overview.DEVICE_ID:
      return {
        ...state,
        deviceId: action.payload,
      };
    case store.overview.IS_FROM_UNASSIGNED:
      return {
        ...state,
        isFromUnassigned: action.payload,
      };
    case store.overview.GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case store.overview.DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    case store.overview.CONN_TYPES:
      return {
        ...state,
        connTypes: action.payload,
      };
    case store.overview.ASSIGNMENTS:
      return {
        ...state,
        assignments: action.payload,
      };
    case store.overview.ERROR_FOR_GET_DEVICE_INFO:
      return {
        ...state,
        errorForGetDeviceInfo: action.payload,
      };
    case store.overview.ERROR_FOR_GET_FLEET_PROXY:
      return {
        ...state,
        errorForGetFleetProxy: action.payload,
      };
    case store.overview.ERROR_FOR_GET_ASSIGNMENT:
      return {
        ...state,
        errorForGetAssignment: action.payload,
      };
    case store.overview.ERROR_FOR_GET_APP_INFO:
      return {
        ...state,
        errorForGetAppInfo: action.payload,
      };
    case store.overview.APP_LIST:
      return {
        ...state,
        appList: action.payload,
      };
    case store.overview.APP_CONTENTS:
      return {
        ...state,
        appContents: action.payload,
      };
    case store.overview.REFRESH_GET_FLEET_PROXY:
      return {
        ...state,
        refreshGetFleetProxy: action.payload,
      };
    case store.overview.REFRESH_GET_ASSIGNMENT:
      return {
        ...state,
        refreshGetAssignment: action.payload,
      };
    case store.overview.REFRESH_GET_APP_INFO:
      return {
        ...state,
        refreshGetAppInfo: action.payload,
      };

    default:
      return state;
  }
};

export default overviewReducer;
