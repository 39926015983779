import { useState } from 'react';

export const useConstructor = (
  callBack = () => {
    return;
  },
) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);

  if (hasBeenCalled) {
    return;
  }

  callBack();
  setHasBeenCalled(true);
};
