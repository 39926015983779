import React, { useEffect, useMemo, useState } from 'react';
import { t } from 'src/utils/commonMethods';
import { useDispatch } from 'react-redux';
import { useStoreState } from 'src/store/useStoreState';
import { CommonLoading } from 'src/components/atom';
import { SectionsTemplate } from 'src/components/template';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import {
  useRefreshForGetAppInfo,
  useRefreshForGetAssignment,
  useRefreshForGetFleetProxy,
} from 'src/hooks/useRefreshHooks';
import {
  apiGetAppInfo,
  apiGetDeviceInfo,
  apiGetFleetProxy,
  apiGetAssignments,
} from 'src/api/apiGetWrapper';
import {
  storeGroups,
  storeDetails,
  storeAppList,
  storeConnTypes,
  storeAppContents,
  storeAssignments,
  storeErrorForGetAppInfo,
  storeErrorForGetAssignment,
  storeErrorForGetDeviceInfo,
  storeErrorForGetFleetProxy,
} from 'src/store/overview/action';

const SectionsPage = () => {
  //private state
  const { overviewState } = useStoreState();
  const { deviceId, useToast, featureFlags, isWex, isFromApps, ecpDeviceV55 } =
    useShellRootContext();
  const {
    groups,
    details,
    errorForGetAppInfo,
    errorForGetDeviceInfo,
    errorForGetFleetProxy,
    errorForGetAssignment,
  } = overviewState;
  const dispatch = useDispatch();
  const [isFetchingForGetAppInfo, setIsFetchingForGetAppInfo] = useState(true);
  const [isFetchingForGetDeviceInfo, setIsFetchingForGetDeviceInfo] = useState(true);
  const [isFetchingForGetFleetProxy, setIsFetchingForGetFleetProxy] = useState(true);
  const [isFetchingForGetAssignment, setIsFetchingForGetAssignment] = useState(true);

  const doApiGetDeviceInfo = () => {
    setIsFetchingForGetDeviceInfo(true);
    apiGetDeviceInfo(deviceId)
      .then((ret) => {
        dispatch(storeErrorForGetDeviceInfo(ret.error));
        dispatch(storeGroups(ret.groups));
        dispatch(storeDetails(ret.details));
        dispatch(storeAppList(ret.details.software.apps));
      })
      .finally(() => {
        setIsFetchingForGetDeviceInfo(false);
      });
  };

  const doApiGetAssignment = () => {
    if (isWex || isFromApps) {
      setIsFetchingForGetAssignment(false);
      return;
    }

    setIsFetchingForGetAssignment(true);
    apiGetAssignments(deviceId)
      .then((ret) => {
        dispatch(storeErrorForGetAssignment(ret.error));
        dispatch(storeAssignments(ret.assignments));
      })
      .finally(() => {
        setIsFetchingForGetAssignment(false);
      });
  };

  const doApiGetAppInfo = (appList) => {
    if (isWex || featureFlags.enableAppList == undefined || !featureFlags.enableAppList) {
      setIsFetchingForGetAppInfo(false);
      return;
    }

    if (featureFlags.enableAppList) {
      setIsFetchingForGetAppInfo(true);
      apiGetAppInfo(appList)
        .then((ret) => {
          dispatch(storeErrorForGetAppInfo(ret.error));
          dispatch(storeAppContents(ret.appContents));
        })
        .finally(() => {
          setIsFetchingForGetAppInfo(false);
        });
    }
  };

  const doApiGetFleetProxy = () => {
    if (isFromApps) {
      setIsFetchingForGetFleetProxy(false);
      return;
    }

    setIsFetchingForGetFleetProxy(true);
    apiGetFleetProxy(details, isWex, ecpDeviceV55)
      .then((ret) => {
        dispatch(storeErrorForGetFleetProxy(ret.error));
        dispatch(storeConnTypes(ret.connTypes));
      })
      .finally(() => {
        setIsFetchingForGetFleetProxy(false);
      });
  };

  useEffect(() => {
    doApiGetDeviceInfo();
    doApiGetAssignment();
  }, []);

  useEffect(() => {
    if (details) {
      doApiGetAppInfo(details.software.apps);
    }
  }, [details]);

  useEffect(() => {
    if (!isFetchingForGetDeviceInfo && !errorForGetDeviceInfo && details) {
      doApiGetFleetProxy();
    }
  }, [isFetchingForGetDeviceInfo, details]);

  //Refresh
  useRefreshForGetFleetProxy();
  useRefreshForGetAssignment();
  useRefreshForGetAppInfo();

  //Error handling
  useEffect(() => {
    if (
      errorForGetDeviceInfo ||
      errorForGetFleetProxy ||
      errorForGetAssignment ||
      errorForGetAppInfo
    ) {
      renderError();
    }
  }, [errorForGetDeviceInfo, errorForGetFleetProxy, errorForGetAssignment, errorForGetAppInfo]);

  const renderError = () => {
    useToast.addToast({
      id: 'error',
      type: 'negative',
      text: t('section.unable_to_load_data'),
    });
  };

  //Render
  const enableCondition =
    !isFetchingForGetDeviceInfo &&
    !isFetchingForGetFleetProxy &&
    !isFetchingForGetAssignment &&
    !isFetchingForGetAppInfo &&
    details !== null &&
    groups.length !== 0;

  const memoRenderComponent = useMemo(() => {
    if (enableCondition) {
      return <SectionsTemplate />;
    } else if (
      isFetchingForGetDeviceInfo ||
      isFetchingForGetFleetProxy ||
      isFetchingForGetAssignment ||
      isFetchingForGetAppInfo
    ) {
      return <CommonLoading />;
    } else {
      return <></>;
    }
  }, [enableCondition]);

  return <>{memoRenderComponent}</>;
};

export default SectionsPage;
