import React from 'react';
import { t } from 'src/utils/commonMethods';
import { Accordion } from '@veneer/core';
import { anchorIdObj } from 'src/utils/constants';
import { accordionHelper } from 'src/veneerHelper';
import { SolutionSubSection } from 'src/components/organism';
import { HpAccordionTitle, HpSectionWrapper } from 'src/styles/global';

const SolutionSection = () => {
  const { accordionState, accordionFunc } = accordionHelper(createAccordionItem());

  function createAccordionItem() {
    return [
      {
        content: <SolutionSubSection />,
        header: {
          centralArea: <HpAccordionTitle>{t('label.solutions')}</HpAccordionTitle>,
          id: anchorIdObj.solutionsAndServices,
        },
        id: `${anchorIdObj.solutionsAndServices}-body`,
        expanded: true,
      },
    ];
  }

  return (
    <HpSectionWrapper>
      <Accordion
        border="dropShadow"
        items={accordionState.items}
        onExpand={accordionFunc.handleExpand}
        onCollapse={accordionFunc.handleCollapse}
      />
    </HpSectionWrapper>
  );
};

export default SolutionSection;
