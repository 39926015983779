import styled from 'styled-components';
import React from 'react';
import { t } from 'src/utils/commonMethods';
import { CommonLoading } from 'src/components/atom';
import { ErrorBoundary } from 'react-error-boundary';
import { Card, IconXCircle } from '@veneer/core';

type TMfeLoaderProps = {
  component?: string;
  fullScreen?: boolean;
  [key: string]: unknown;
};

const MfeLoader: React.FC<TMfeLoaderProps> = ({ component, ...props }) => {
  const Component = React.lazy(() => System.import(component));

  const ErrorFallback = () => (
    <Card
      border="dropShadow"
      customStyle={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      content={
        <>
          <IconXCircle color="red6" filled size={48} />
          <ErrorText>{t('error_text')}</ErrorText>
        </>
      }
    />
  );

  return (
    <Container data-testid="mfe-loader">
      {component ? (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <React.Suspense fallback={<CommonLoading />}>
            <Component {...props} />
          </React.Suspense>
        </ErrorBoundary>
      ) : (
        <Card
          appearance="dropShadow"
          data-testid="loader-wrapper"
          customStyle={{ width: '100%', height: '100%' }}
        />
      )}
    </Container>
  );
};

export default MfeLoader;

const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  max-width: 100%;

  & > * {
    width: 100%;
    max-width: 100%;
  }
`;

const ErrorText = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: red;
  text-align: center;
  max-width: 250px;
  margin-top: 16px;
`;
