import { store } from 'src/utils/constants';

export const storeStartRootComponent = (_payload) => {
  return {
    type: store.overview.START_ROOT_COMPONENT,
    payload: _payload,
  };
};

export const storeDeviceId = (_payload) => {
  return {
    type: store.overview.DEVICE_ID,
    payload: _payload,
  };
};

export const storeIsFromUnassigned = (_payload) => {
  return {
    type: store.overview.IS_FROM_UNASSIGNED,
    payload: _payload,
  };
};

export const storeGroups = (_payload) => {
  return {
    type: store.overview.GROUPS,
    payload: _payload,
  };
};

export const storeDetails = (_payload) => {
  return {
    type: store.overview.DETAILS,
    payload: _payload,
  };
};

export const storeConnTypes = (_payload) => {
  return {
    type: store.overview.CONN_TYPES,
    payload: _payload,
  };
};

export const storeAssignments = (_payload) => {
  return {
    type: store.overview.ASSIGNMENTS,
    payload: _payload,
  };
};

export const storeAppList = (_payload) => {
  return {
    type: store.overview.APP_LIST,
    payload: _payload,
  };
};

export const storeAppContents = (_payload) => {
  return {
    type: store.overview.APP_CONTENTS,
    payload: _payload,
  };
};

export const storeErrorForGetDeviceInfo = (_payload) => {
  return {
    type: store.overview.ERROR_FOR_GET_DEVICE_INFO,
    payload: _payload,
  };
};

export const storeErrorForGetFleetProxy = (_payload) => {
  return {
    type: store.overview.ERROR_FOR_GET_FLEET_PROXY,
    payload: _payload,
  };
};

export const storeErrorForGetAssignment = (_payload) => {
  return {
    type: store.overview.ERROR_FOR_GET_ASSIGNMENT,
    payload: _payload,
  };
};

export const storeErrorForGetAppInfo = (_payload) => {
  return {
    type: store.overview.ERROR_FOR_GET_APP_INFO,
    payload: _payload,
  };
};

export const storeRefreshGetFleetProxy = (_payload) => {
  return {
    type: store.overview.REFRESH_GET_FLEET_PROXY,
    payload: _payload,
  };
};

export const storeRefreshGetAssignment = (_payload) => {
  return {
    type: store.overview.REFRESH_GET_ASSIGNMENT,
    payload: _payload,
  };
};

export const storeRefreshGetAppInfo = (_payload) => {
  return {
    type: store.overview.REFRESH_GET_APP_INFO,
    payload: _payload,
  };
};
