import React from 'react';
import styled from 'styled-components';
import { t } from 'src/utils/commonMethods';
import { AnchorMenu } from '@veneer/core';
import { useStoreState } from 'src/store/useStoreState';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { anchorIdObj, defaultAnchorPosition } from 'src/utils/constants';

const SideAnchor = () => {
  const { overviewState } = useStoreState();
  const { isFromUnassigned } = overviewState;
  const { ecpDeviceV55, containerId, isWex, isFromApps } = useShellRootContext();

  const createAnchorMenuItems = () => {
    const _anchorMenuItems = [
      {
        anchor: anchorIdObj.generalInfo,
        label: t('label.general_information'),
      },
      {
        anchor: anchorIdObj.connType,
        label: t('label.connectivity_type'),
      },
      {
        anchor: anchorIdObj.usage,
        label: t('label.usage'),
      },
      {
        anchor: anchorIdObj.warranty,
        label: t('label.warranty'),
      },
      {
        anchor: anchorIdObj.policy,
        label: t('label.policies'),
      },
      {
        anchor: anchorIdObj.solutionsAndServices,
        label: t('label.solutions'),
      },
    ];
    let excludeCondition;

    if (ecpDeviceV55) {
      excludeCondition = (obj) =>
        !(
          (obj.anchor === anchorIdObj.warranty && isFromUnassigned) ||
          (obj.anchor === anchorIdObj.policy && isFromUnassigned) ||
          (obj.anchor === anchorIdObj.solutionsAndServices && isFromUnassigned)
        );
    } else if (isWex) {
      excludeCondition = (obj) =>
        !(
          obj.anchor === anchorIdObj.usage ||
          obj.anchor === anchorIdObj.warranty ||
          obj.anchor === anchorIdObj.policy ||
          obj.anchor === anchorIdObj.solutionsAndServices
        );
    } else if (isFromApps) {
      excludeCondition = (obj) =>
        !(
          obj.anchor === anchorIdObj.usage ||
          obj.anchor === anchorIdObj.warranty ||
          obj.anchor === anchorIdObj.policy ||
          obj.anchor === anchorIdObj.connType
        );
    } else {
      excludeCondition = (obj) =>
        !(obj.anchor === anchorIdObj.usage || obj.anchor === anchorIdObj.warranty);
    }

    return _anchorMenuItems.filter((obj) => excludeCondition(obj));
  };

  return (
    <Wrapper>
      <AnchorMenu
        id="react-ecp-device-details-overview-anchor-menu"
        containerId={containerId}
        items={createAnchorMenuItems()}
        position={defaultAnchorPosition}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: sticky;
  top: 10px;
  height: 100%;

  @media (max-width: 768px) {
    width: 0%;
    transition: all 1s ease;
  }
  @media (min-width: 768px) {
    width: 20%;
    transition: all 1s ease;
  }
`;

export default SideAnchor;
