import React from 'react';
import { t } from 'src/utils/commonMethods';
import { Accordion } from '@veneer/core';
import { anchorIdObj } from 'src/utils/constants';
import { useStoreState } from 'src/store/useStoreState';
import { accordionHelper } from 'src/veneerHelper';
import { HpAccordionTitle, HpSectionWrapper } from 'src/styles/global';
import { GeneralDeviceInfo, NetworkAdapterInfo } from 'src/components/molecule';

const GeneralInfoSection = () => {
  const { overviewState } = useStoreState();
  const { groups, details } = overviewState;
  const { accordionState, accordionFunc } = accordionHelper(createAccordionItems());

  function createAccordionItems() {
    const wiredEthernet = [],
      wifiStation = [];

    details.network.adapters.forEach((adapter) => {
      if (adapter.name.startsWith('eth')) wiredEthernet.push(adapter);
      else if (adapter.name.startsWith('wifi')) wifiStation.push(adapter);
    });

    return [
      {
        content: (
          <div className={'sections__sub-accordion-item'}>
            <GeneralDeviceInfo deviceInfo={details} groups={groups} />
            {wiredEthernet.length > 0 && (
              <NetworkAdapterInfo adapters={wiredEthernet} adapterType={'wired'} />
            )}
            {wifiStation.length > 0 && (
              <NetworkAdapterInfo adapters={wifiStation} adapterType={'wireless'} />
            )}
          </div>
        ),
        header: {
          centralArea: <HpAccordionTitle>{t('label.general_information')}</HpAccordionTitle>,
          endArea: <></>,
          id: `${anchorIdObj.generalInfo}`,
        },
        id: `${anchorIdObj.generalInfo}-body`,
        expanded: true,
      },
    ];
  }

  return (
    <HpSectionWrapper>
      <Accordion
        border="dropShadow"
        items={accordionState.items}
        onExpand={accordionFunc.handleExpand}
        onCollapse={accordionFunc.handleCollapse}
      />
    </HpSectionWrapper>
  );
};

export default GeneralInfoSection;
