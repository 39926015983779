import React from 'react';
import styled from 'styled-components';
import { getProps } from 'src/utils/commonMethods';
import { useStoreState } from 'src/store/useStoreState';
import { IconSmartApp, ListItem } from '@veneer/core';
import { AppStatus, AppListNoItems, CommonLoading } from 'src/components/atom';

const AppList = () => {
  const { overviewState } = useStoreState();
  const { appList, appContents, errorForGetAppInfo, refreshGetAppInfo } = overviewState;
  const getLocaleDescription = (localeInfoList) => {
    if (localeInfoList.length === 0) {
      return '--';
    }
    let enUS_idx = 0;
    const { language, country } = getProps();
    const currentLocale = `${language}_${country.toUpperCase()}`;

    const filteredLocalInfo = localeInfoList.filter((item, idx) => {
      if (item.locale === 'en_US') {
        enUS_idx = idx;
      }
      return item.locale === currentLocale;
    });

    if (filteredLocalInfo.length > 0) {
      return filteredLocalInfo[0].description;
    }
    return localeInfoList[enUS_idx].description ?? '--';
  };

  const getIconImg = (url) => {
    if (url === undefined || url === '--') {
      return <IconSmartApp size={40} />;
    }
    return <StyledApp src={url} />;
  };

  const parsedAppList = appList.map((app) => {
    const matchApp = appContents.filter((item) => item.appUuid === app.uuid);

    if (matchApp.length === 0) {
      return {
        ...app,
        icon: getIconImg('--'),
        title: app.appTitle,
        detail: '--',
      };
    }
    return {
      ...app,
      icon: getIconImg(matchApp[0].icon),
      title: matchApp[0].title,
      detail: getLocaleDescription(matchApp[0].localeInfoList),
    };
  });
  const memoRenderAppList = React.useMemo(() => {
    if (refreshGetAppInfo) return <CommonLoading />;
    else if (appList.length == 0) return <AppListNoItems error={errorForGetAppInfo} />;
    else
      return parsedAppList.map((app) => (
        <ListItem
          key={'app-list-item__' + app.uuid}
          data-testid="service-item"
          leadingArea={app.icon}
          centerArea={
            <TextWrapper>
              <Subtitle>{app.title}</Subtitle>
              <DetailText title={app.detail}>{app.detail}</DetailText>
            </TextWrapper>
          }
          trailingArea={<AppStatus appState={app.appState} deployState={app.deployState} />}
        />
      ));
  }, [refreshGetAppInfo, appList, appContents, errorForGetAppInfo]);

  return <>{memoRenderAppList}</>;
};

const TextWrapper = styled.div`
  display: inline-grid;
  margin-left: 8.5px;
  min-width: 316px;
`;

const Subtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
`;

const DetailText = styled.label`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #404040;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledApp = styled.img`
  width: 40px !important;
  height: 40px !important;
`;

export default AppList;
