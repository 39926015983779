import React from 'react';
import styled from 'styled-components';
import { IconInfo, Tooltip } from '@veneer/core';

const ContentItem = (props: {
  id: string;
  title: string;
  value: any;
  marginTop: number;
  addInfo?: boolean;
  infoValue?: string;
}) => {
  return (
    <Wrapper marginTop={props.marginTop}>
      <Title>
        {props.title + ' '}
        {props.addInfo == true && (
          <IconInfoWrapper data-testid="content-item-icon-info-wrapper">
            <Tooltip arrow placement="bottom" content={props.infoValue}>
              <IconInfo size={12.8} />
            </Tooltip>
          </IconInfoWrapper>
        )}
      </Title>
      <Value data-testid={props.id}>{props.value}</Value>
    </Wrapper>
  );
};

ContentItem.defaultProps = {
  marginTop: 16,
};

const Wrapper = styled.div`
  margin-top: ${(props) => {
    return `${props.marginTop}px`;
  }};
`;

const Title = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #737373;
  white-space: pre;
`;

const Value = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #212121;
`;

const IconInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2px;
`;

export default ContentItem;
