import { useEffect } from 'react';
import {
  storeAppContents,
  storeAppList,
  storeAssignments,
  storeConnTypes,
  storeErrorForGetAppInfo,
  storeErrorForGetAssignment,
  storeErrorForGetFleetProxy,
  storeRefreshGetAppInfo,
  storeRefreshGetAssignment,
  storeRefreshGetFleetProxy,
} from 'src/store/overview/action';
import { apiGetAppInfo, apiGetAssignments, apiGetFleetProxy } from 'src/api/apiGetWrapper';
import { useDispatch } from 'react-redux';
import { useStoreState } from 'src/store/useStoreState';
import { useShellRootContext } from 'src/contexts/ShellRoot';

export const useRefresh = (refreshFlag, apiFunc, apiProps, storeError, storeData, storeRefresh) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (refreshFlag) {
      dispatch(storeError(false));
      apiFunc(apiProps)
        .then((ret) => {
          storeData(ret);
        })
        .finally(() => {
          dispatch(storeRefresh(false));
        });
    }
  }, [refreshFlag]);
};

export const useRefreshForGetFleetProxy = () => {
  const dispatch = useDispatch();
  const { overviewState } = useStoreState();
  const { refreshGetFleetProxy, details } = overviewState;
  const storeData = (ret) => {
    dispatch(storeErrorForGetFleetProxy(ret.error));
    dispatch(storeConnTypes(ret.connTypes));
  };
  useRefresh(
    refreshGetFleetProxy,
    apiGetFleetProxy,
    details,
    storeErrorForGetFleetProxy,
    storeData,
    storeRefreshGetFleetProxy,
  );
};

export const useRefreshForGetAssignment = () => {
  const dispatch = useDispatch();
  const { overviewState } = useStoreState();
  const { refreshGetAssignment } = overviewState;
  const { deviceId } = useShellRootContext();
  const storeData = (ret) => {
    dispatch(storeErrorForGetAssignment(ret.error));
    dispatch(storeAssignments(ret.assignments));
  };
  useRefresh(
    refreshGetAssignment,
    apiGetAssignments,
    deviceId,
    storeErrorForGetAssignment,
    storeData,
    storeRefreshGetAssignment,
  );
};

export const useRefreshForGetAppInfo = () => {
  const dispatch = useDispatch();
  const { overviewState } = useStoreState();
  const { refreshGetAppInfo } = overviewState;
  const { deviceId } = useShellRootContext();
  const storeData = (ret) => {
    dispatch(storeErrorForGetAppInfo(ret.error));
    dispatch(storeAppList(ret.appList));
    dispatch(storeAppContents(ret.appContents));
  };
  useRefresh(
    refreshGetAppInfo,
    apiGetAppInfo,
    deviceId,
    storeErrorForGetAppInfo,
    storeData,
    storeRefreshGetAppInfo,
  );
};
